<template>
  <component :is="currentDashboard"></component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import adminDashboard from './AdminDashboard'
import customerDashboard from './CustomerDashboard'
import customArtistDashboard from './CustomArtistDashboard'
import artistDashboard from './ArtistDashboard'

export default {
  data () {
    return {
      currentDashboard: null
    }
  },
  async created () {
    if (this.isAdmin) {
      this.currentDashboard = adminDashboard
    } else if (this.isPublishedArtist) {
      this.currentDashboard = artistDashboard
    } else if (this.isCustomArtist) {
      this.currentDashboard = customArtistDashboard
    } else {
      this.currentDashboard = customerDashboard
    }
  },
  computed: mapState({
    ...mapGetters([
      'isAdmin',
      'isCustomer',
      'isCustomArtist',
      'isPublishedArtist']),
  }),
  methods: {
  }
}
</script>

